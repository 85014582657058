export function getAppConfig() {
    if (typeof window === 'undefined') {
        return {
            CLICK_OUT_URL: process.env.CLICK_OUT_URL,
            ACCOUNT_URL: process.env.ACCOUNT_URL,
            BASE_FORM_URL: process.env.BASE_FORM_URL,
            NAME_WHITELABEL: process.env.NAME_WHITELABEL,
            CLICK_SOURCE_WHITELABEL: process.env.CLICK_SOURCE_WHITELABEL,
            RELEASE_VERSION: process.env.RELEASE_VERSION,
            ENABLE_SECURE_COOKIE: process.env.ENABLE_SECURE_COOKIE,
            SESSION_DURATION: process.env.SESSION_DURATION,
        }
    }

    return window.appConfig
}
