import { NextRouter } from 'next/router'

/**
 * For routes, `pathname` returns the identifier rather
 * than the actual value. For example, rather than
 * return /all-cards, it'll return /[category] which
 * is pointless. Here we use `asPath` and remove the
 * query string
 *
 * @param asPath The `asPath` from NextRouter
 * @returns The pathname
 */
export function getPathnameFromAsPath(asPath: NextRouter['asPath']) {
    const queryStartIndex = asPath.indexOf('?')
    return asPath.slice(0, queryStartIndex > -1 ? queryStartIndex : undefined)
}
