import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

export const categories = {
    [CreditCardProductCategory.BestCards]: {
        path: 'all-cards',
        name: 'All Cards',
    },
    [CreditCardProductCategory.BalanceTransfer]: {
        path: 'balance-transfer',
        name: 'Balance Transfer',
    },
    [CreditCardProductCategory.Cashback]: {
        path: 'cashback',
        name: 'Cashback',
    },
    [CreditCardProductCategory.PoorCredit]: {
        path: 'credit-cards-bad-credit',
        name: 'Poor Credit',
    },
    [CreditCardProductCategory.Purchase]: {
        path: 'purchase',
        name: '0% Purchases',
    },
    [CreditCardProductCategory.Rewards]: {
        path: 'rewards',
        name: 'Rewards',
    },
    [CreditCardProductCategory.Combined]: {
        path: '0-percent',
        name: 'Balance & Purchases',
    },
    [CreditCardProductCategory.Travel]: {
        path: 'travel',
        name: 'Travel',
    },
} as const

export const categoryPaths = Object.keys(categories).map(
    category => categories[category as keyof typeof categories].path
)

export type CategoryPath = (typeof categories)[keyof typeof categories]['path']
