import { useRouter } from 'next/router'
import { useContext } from 'react'

import { FeatureFlag } from '@appTypes/PagesApi.interface'

import { FeatureFlagContext } from '@context/FeatureFlagContext'

const useFeatureFlag = <TFlag extends keyof FeatureFlag>(
    flag: TFlag,
    defaultValue?: FeatureFlag[TFlag]
): FeatureFlag[TFlag] => {
    const router = useRouter()
    const context = useContext(FeatureFlagContext)

    if (router.query[flag] != null) {
        return router.query[flag] as FeatureFlag[TFlag]
    }

    return context.featureFlags[flag] ?? defaultValue
}

export default useFeatureFlag
