import Cookies from 'js-cookie'

import { getAppConfig } from '@utils/getAppConfig'

import { ACCOUNT_SESSION_TOKEN_COOKIE_NAME } from '@config/cookies'

export default function resetLogoutTimeout() {
    const appConfig = getAppConfig()

    const sessionDuration = Number(appConfig.SESSION_DURATION ?? 600)
    const inactivityTimeout = new Date(+new Date() + sessionDuration * 1000)

    if (typeof document !== 'undefined') {
        const pulseSessionToken = Cookies.get(ACCOUNT_SESSION_TOKEN_COOKIE_NAME)

        if (pulseSessionToken != null) {
            Cookies.set(ACCOUNT_SESSION_TOKEN_COOKIE_NAME, pulseSessionToken, {
                path: '/',
                expires: inactivityTimeout,
                secure: appConfig.ENABLE_SECURE_COOKIE === '1',
            })
        }
    }
}
