import {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

const getStatusModifier = (item: ScoredCreditCardProduct) => {
    return ['PreApprovedProduct', 'PreApprovedRate'].indexOf(item.status) * 0.1
}

const getComparisonValues = (
    items: { a: ScoredCreditCardProduct; b: ScoredCreditCardProduct },
    propValue: (item: ScoredCreditCardProduct) => number | null,
    defaultValue: number
) => {
    return {
        a: propValue(items.a) ?? defaultValue,
        b: propValue(items.b) ?? defaultValue,
    }
}
export type ComparatorKey =
    | 'apr'
    | 'display-order'
    | 'bt-offer'
    | 'purchase-offer'
    | 'score'
    | 'transfer-fee'
    | 'international-charges'
    | 'max-credit-limit'
    | 'match-factor'
    | 'combined-introductory-offers'

const comparators: Record<
    ComparatorKey,
    (a: ScoredCreditCardProduct, b: ScoredCreditCardProduct) => number
> = {
    apr: (a, b) => {
        const values = getComparisonValues(
            { a, b },
            item => {
                if (item?.guaranteedRate?.guaranteedAPR != null) {
                    return item.guaranteedRate.guaranteedAPR
                }
                return item.offerDetail.representativeAPR
            },
            999999999
        )
        return values.a - values.b
    },
    'display-order': (a, b) => {
        return a.displayOrder - b.displayOrder
    },
    'bt-offer': (a, b) => {
        const values = getComparisonValues(
            { a, b },
            item => item.endOfBalanceTransferPeriod,
            0
        )
        return +values.b - +values.a
    },
    'purchase-offer': (a, b) => {
        const values = getComparisonValues(
            { a, b },
            item => item.endOfPurchasePeriod,
            0
        )
        return +values.b - +values.a
    },
    score: (a, b) => {
        return b.score + getStatusModifier(b) - (a.score + getStatusModifier(a))
    },
    'transfer-fee': (a, b) => {
        const values = getComparisonValues(
            { a, b },
            item => item.offerDetail.introBalanceTransferFee,
            0
        )
        return values.a - values.b
    },
    'international-charges': (a, b) => {
        const values = getComparisonValues(
            { a, b },
            item => item.offerDetail.internationalCharges ?? null,
            0
        )
        return values.a - values.b
    },
    'max-credit-limit': (a, b) => {
        const values = getComparisonValues(
            { a, b },
            item => item.maxCreditLimit ?? null,
            0
        )
        return values.b - values.a
    },
    'match-factor': (a, b) => {
        const values = getComparisonValues(
            { a, b },
            item => item.categoryScore,
            999999999
        )
        // sort in ascending order
        return +values.a - +values.b
    },
    'combined-introductory-offers': (a, b) => {
        const values = getComparisonValues(
            { a, b },
            item => item.tmScoreCombinedIntroductoryOffers ?? null,
            0
        )
        return +values.b - +values.a
    },
}

const multiCompare = (
    a: ScoredCreditCardProduct,
    b: ScoredCreditCardProduct,
    comparatorKeys: ComparatorKey[]
): number => {
    const comparatorKey = comparatorKeys ? comparatorKeys[0] : null
    if (comparatorKey) {
        return (
            comparators[comparatorKey](a, b) ||
            multiCompare(a, b, comparatorKeys.slice(1))
        )
    }
    return 0
}

const getComparator = (comparatorKeys: ComparatorKey[]) => {
    return (a: ScoredCreditCardProduct, b: ScoredCreditCardProduct) =>
        multiCompare(a, b, comparatorKeys)
}

type ComparatorKeys<TCategoryComparatorKey extends string> = {
    [TCategory in CreditCardProductCategory]: {
        [Key in TCategoryComparatorKey]?: ComparatorKey[]
    }
}

const getComparatorKeys = <
    TSortComparatorKeys extends ComparatorKeys<any>,
    TCategory extends CreditCardProductCategory,
    TSortKey extends keyof TSortComparatorKeys[TCategory],
    TComparatorKey extends TSortComparatorKeys[TCategory][TSortKey]
>(
    categorySortKeyComparatorKeys: TSortComparatorKeys,
    category: TCategory,
    sortKey: TSortKey
): TComparatorKey => {
    const comparatorKeys = categorySortKeyComparatorKeys[category][
        sortKey
    ] as unknown as TComparatorKey
    return comparatorKeys
}

type Options<TSortComparatorKeys extends ComparatorKeys<any>> = {
    cards: ScoredCreditCardProduct[]
    category: CreditCardProductCategory
    sortKey: keyof TSortComparatorKeys[CreditCardProductCategory]
    categorySortKeyComparatorKeys: TSortComparatorKeys
}

export const sortCardsBySortKey = <
    TSortComparatorKeys extends ComparatorKeys<any>
>(
    options: Options<TSortComparatorKeys>
): ScoredCreditCardProduct[] => {
    const comparatorKeys = getComparatorKeys(
        options.categorySortKeyComparatorKeys,
        options.category,
        options.sortKey
    )

    if (!options.cards || !comparatorKeys) {
        return options.cards
    }

    return [...options.cards].sort(getComparator(comparatorKeys))
}
