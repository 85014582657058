import { useLoggerMutation } from '@queries/useLoggerMutation'

import { LoggerKey } from '@config/logger'

const useLogger = () => {
    const { mutateAsync } = useLoggerMutation()

    const disable =
        typeof window === 'undefined' || process.env.NODE_ENV === 'development'

    return {
        logError: <TData>(message: LoggerKey, data: TData) => {
            if (disable) {
                console.error(`[Logger:disabled] Log Error`, message, data)
                return
            }
            return mutateAsync({
                data,
                level: 'error',
                message,
            })
        },

        logInfo: <TData>(message: LoggerKey, data: TData) => {
            if (disable) {
                console.log(`[Logger:disabled] Log Info`, message, data)
                return
            }
            return mutateAsync({
                data,
                level: 'info',
                message,
            })
        },
    }
}

export default useLogger
