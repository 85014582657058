import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import { categories } from '@config/categories'

export const getCategoryIdForPath = (
    categoryPath: string
): CreditCardProductCategory => {
    let categoryId = CreditCardProductCategory.BestCards

    for (const [key, value] of Object.entries(categories)) {
        if (categoryPath == value?.path) {
            categoryId = key as CreditCardProductCategory
            break
        }
    }

    return categoryId
}
