import { useContext } from 'react'

import { BasinEvents } from '@appTypes/BasinEvents.interface'

import { BasinContext } from '@context/BasinContext'

export const useBasin = () => {
    const context = useContext(BasinContext)

    const disable =
        typeof window === 'undefined' || process.env.NODE_ENV === 'development'

    return {
        ids: context.channel?.uuids,

        send: <TEventName extends keyof BasinEvents>(
            eventName: TEventName,
            eventData: BasinEvents[TEventName]
        ) => {
            if (disable) {
                console.log(`[Basin:disabled] Send: ${eventName}`, eventData)
                return
            }
            context.channel?.send(eventName, eventData)
        },

        updateMetadata: (metadata: Record<string, string | any>) => {
            if (disable) {
                console.log(`[Basin:disabled] Metadata update:`, metadata)
                return
            }
            context.channel?.updateMetadata(metadata)
        },
    }
}
