var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v1.44.20"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

import { getAppConfig } from '@utils/getAppConfig'
import {
    getSanitisedBreadcrumbUrl,
    getSanitisedUrl,
} from '@utils/getSanitisedUrl'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const denyUrls = [
    /eu-west-2\.amazonaws\.com/i,
    /trc-events\.taboola\.com/i,
    /in\.hotjar\.com/i,
    /widget\.trustpilot\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    'https://websdk.appsflyer.com',
]

if (process.env.NODE_ENV != 'development' && !process.env.NEXT_PUBLIC_CI) {
    const whitelabel = getAppConfig().NAME_WHITELABEL

    Sentry.init({
        dsn:
            SENTRY_DSN ||
            'https://8294de7ef2bc4df3b9297574129fdda4@o1144767.ingest.sentry.io/4504535889870848',
        tracesSampleRate: 0.5,
        denyUrls,
        environment: process.env.NEXT_PUBLIC_APP_ENV,
        initialScope: {
            tags: {
                whitelabel,
            },
        },
        dist: whitelabel,
        beforeBreadcrumb(breadcrumb) {
            if (
                typeof breadcrumb.message === 'string' &&
                breadcrumb.message.startsWith('Basin:')
            ) {
                breadcrumb.type = 'info'
                breadcrumb.category = 'basin'
            }
            if (breadcrumb.category === 'navigation') {
                if (breadcrumb.data?.from != null) {
                    breadcrumb.data.from = getSanitisedBreadcrumbUrl(
                        breadcrumb.data.from
                    )
                }
                if (breadcrumb.data?.to != null) {
                    breadcrumb.data.to = getSanitisedBreadcrumbUrl(
                        breadcrumb.data.to
                    )
                }
            }
            return breadcrumb
        },
        beforeSend: event => {
            if (event.request?.url != null) {
                event.request.url = getSanitisedUrl(event.request.url)
            }
            return event
        },
    })
}
