import { useContext } from 'react'

import { FeatureFlagContext } from '@context/FeatureFlagContext'

const useFeatureFlags = () => {
    const context = useContext(FeatureFlagContext)

    return {
        featureFlags: context.featureFlags,
    }
}

export default useFeatureFlags
