import { FeatureFlag } from '@appTypes/PagesApi.interface'

export function getVariationsFromFeatureFlags(
    featureFlags: FeatureFlag
): string[] {
    if (featureFlags == null || Object.keys(featureFlags).length === 0) {
        return []
    }

    return Object.keys(featureFlags).reduce((variations, featureFlag) => {
        return variations.concat([
            `${featureFlag}.${(featureFlags as any)[featureFlag]}`,
        ])
    }, [] as string[])
}
