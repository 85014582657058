const denyQueries = [
    'customerId',
    'scoreId',
    'scoreid',
    'scoreIdEncoded',
    'mlt',
]

function sanitizeQuery(searchParams: URLSearchParams) {
    if (!(searchParams instanceof URLSearchParams)) {
        return null
    }
    denyQueries.forEach(denyQueryKey => {
        if (searchParams.has(denyQueryKey)) {
            searchParams.set(denyQueryKey, 'XXX')
        }
    })
    return searchParams
}

export function getSanitisedBreadcrumbUrl(url: string) {
    if (typeof url === 'string' && url.includes('?')) {
        const urlSplit = url.split('?')
        const searchParams = new URLSearchParams(urlSplit[1])
        sanitizeQuery(searchParams)
        return `${urlSplit[0]}?${searchParams.toString()}`
    }
    return url
}

export function getSanitisedUrl(url: string) {
    const parsedUrl = new URL(url)
    sanitizeQuery(parsedUrl.searchParams)
    return parsedUrl.toString()
}
