import {
    QueryParametersAppState,
    SupportedQueryParameters,
} from './getAppStateFromQueryParameters'

export function getQueryParametersFromAppState(
    appState: QueryParametersAppState
): SupportedQueryParameters {
    const queryParameters = {
        card: appState.scoredCardUrl,
        cardCode: appState.cardCode,
        cardMatch: appState.cardMatchCode,
        isCardSpecific:
            appState.isCardSpecific != null
                ? `${appState.isCardSpecific}`
                : undefined,

        csrc: appState.clickSource,

        debug: appState.debug,
        newToUk: appState.newToUk,

        cam: appState.campaign,
        gclid: appState.googleClickId,
        k: appState.keyword,
        m: appState.mediaSource,
        adg: appState.adgroup,
        sendId: appState.sendId,

        show: appState.numberOfCardsToShow?.toString(),
        sortby: appState.sortBy,

        scoreId: appState.scoreId,
        customerId: appState.customerId,
        dormantScoreId: appState.dormantScoreId,
        showAppPromotion: appState.showAppPromotion,

        flashMessage: appState.flashMessage,
    } as const

    return Object.keys(queryParameters).reduce((all, queryKey) => {
        const qk = queryKey as keyof typeof queryParameters

        if (queryParameters[qk] != null) {
            all[qk] = queryParameters[qk]!
        }
        return all
    }, {} as Record<string, string>)
}
