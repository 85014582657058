import FlashMessage from '@totallymoney/ui/components/FlashMessage'

import useAppQueryState from '@hooks/useAppQueryState'

import { flashMessages } from '@config/flashMessages'

const GlobalFlashMessage = () => {
    const { appQueryState } = useAppQueryState()

    if (!appQueryState.flashMessage) return null

    const flashMessage = flashMessages[appQueryState.flashMessage]

    if (!flashMessage) return null

    return (
        <FlashMessage variant={flashMessage.variation}>
            {flashMessage.title}
        </FlashMessage>
    )
}

export default GlobalFlashMessage
