export enum LoggerKey {
    NotFoundPageView = 'notFoundPageView',
    AuthControllerReauth = 'authControllerReauth',
    ScoredTableErrorOffersRedirect = 'scoredTableErrorOffersRedirect',
    ScoredTableErrorView = 'scoredTableErrorView',
    PostCreditCardsEmailTriggerError = 'postCreditCardsEmailTriggerError',
    AlternativeProductsErrorOffersRedirect = 'alternativeProductsErrorOffersRedirect',
    AlternativeProductsErrorView = 'alternativeProductsErrorView',
    MatchFactorRankingError = 'matchFactorRankingError',
}
