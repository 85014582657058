import {
    GlobalFooter,
    GlobalNavigation,
    getStateVariant, // @ts-ignore
} from '@mediaingenuity/totallymoney.globalassets'
import { captureException, setUser } from '@sentry/nextjs'
import {
    Hydrate,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import totallyMoneyTheme from '@totallymoney/ui/theme/TotallyMoneyTheme'
import type { AppProps } from 'next/app'
import React, { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { ThemeProvider } from 'styled-components'

import { getAuthErrorInfo } from '@utils/getAuthErrorInfo'
import { getAuthRedirectUrl } from '@utils/getAuthRedirectUrl'

import { MockQueryUnion } from '@api/mocks/get-scored-categories'

import { BasinProvider } from '@context/BasinContext'
import { FeatureFlagProvider } from '@context/FeatureFlagContext'

import AuthController from '@components/AuthController'
import DevToolBanner from '@components/DevToolBanner/DevToolBanner'
import DevToolBannerInfo from '@components/DevToolBannerInfo'
import DynamicDevTools from '@components/DevTools/DynamicDevTools'
import GlobalFlashMessage from '@components/GlobalFlashMessage'
import LogVisitEvent from '@components/LogEvents'

import GlobalStyle from '../styles/GlobalStyles'

export default function MyApp({ Component, pageProps, router }: AppProps) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    mutations: {
                        onError: error => {
                            captureException(error)
                        },
                    },
                    queries: {
                        onError: error => {
                            captureException(error)

                            if (
                                typeof window !== 'undefined' &&
                                getAuthErrorInfo(error) != null
                            ) {
                                window.location.href = getAuthRedirectUrl()
                            }
                        },
                    },
                },
            })
    )

    const variant = getStateVariant()
    const hideNavigation = router.query.hidenavigation
    const environment =
        process.env.NEXT_PUBLIC_APP_ENV === 'development' ||
        process.env.NEXT_PUBLIC_APP_ENV == 'test'
            ? 'staging'
            : process.env.NEXT_PUBLIC_APP_ENV

    useEffect(() => {
        if (router.query.customerId != null) {
            const customerId = router.query.customerId as string

            setUser({
                username: customerId,
                id: customerId,
            })
        }
    }, [router.query.customerId])

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
                <BasinProvider environment={environment}>
                    <FeatureFlagProvider>
                        <ThemeProvider theme={totallyMoneyTheme}>
                            <GlobalStyle />
                            <AuthController />
                            <LogVisitEvent />

                            {process.env.NODE_ENV === 'development' ? (
                                <DevToolBanner />
                            ) : null}

                            {process.env.NODE_ENV === 'development' ? (
                                <DevToolBannerInfo
                                    queryScoreId={
                                        router?.query?.[
                                            'scoreId'
                                        ] as MockQueryUnion
                                    }
                                />
                            ) : null}

                            <main>
                                {!hideNavigation ? (
                                    <GlobalNavigation
                                        environment={environment}
                                    />
                                ) : null}

                                <GlobalFlashMessage />

                                {router.query.devtools === 'true' ? (
                                    <DynamicDevTools />
                                ) : null}
                                <Component {...pageProps} />
                                <GlobalFooter
                                    environment={environment}
                                    defaultVariant={variant}
                                />
                            </main>
                        </ThemeProvider>
                    </FeatureFlagProvider>
                </BasinProvider>
            </Hydrate>
            <ReactQueryDevtools />
        </QueryClientProvider>
    )
}
