import Cookies from 'js-cookie'

import { QueryParametersAppState } from './getAppStateFromQueryParameters'

/**
 * Get the tracking data by order of precedence:
 *
 * [1] Use the data from the query parameters
 * [2] Use the data from the cookies set by google tag manager
 *
 * @param appState - The app state generated from the query parameters
 * @returns The tracking data
 */
export const getMarketingTrackingData = (
    appState?: QueryParametersAppState
) => {
    return {
        m: appState?.mediaSource ?? Cookies.get('tm-m'),
        sendId: appState?.sendId ?? Cookies.get('tm-sendid'),
        cam: appState?.campaign ?? Cookies.get('tm-cam'),
        k: appState?.keyword ?? Cookies.get('tm-k'),
        adg: appState?.adgroup ?? Cookies.get('tm-adg'),
        gclid: appState?.googleClickId ?? Cookies.get('tm-gclid'),
    }
}
