export enum CardWhitelabelClickSource {
    AccountInitial = 882,
    AccountLatest = 883,
}

export enum CreditCardsAccountType {
    AccountInitial = 'account-initial',
    AccountLatest = 'account-latest',
}

export enum ApplicationURLTriggerLocation {
    Deeplink = 'deeplink',
    Header = 'header',
    Row = 'row',
    SponsoredRow = 'sponsored-row',
}

export enum RateType {
    Fixed = 'fixed',
    Variable = 'variable',
}

export enum OfferType {
    MarketWide = 'Market-wide',
    TMExclusive = 'TM exclusive',
}

export enum GuaranteedOfferType {
    CreditLimit = 'CreditLimit',
    GuaranteedApr = 'GuaranteeedApr',
    GuaranteedAprAndCreditLimit = 'GuaranteedAprAndCreditLimit',
    GuaranteedCreditLimit = 'GuaranteedCreditLimit',
    MarketingOffer = 'MarketingOffer',
    PreApprovedAndGuaranteedApr = 'PreApprovedAndGuaranteedApr',
    PreApprovedAndGuaranteedCreditLimit = 'PreApprovedAndGuaranteedCreditLimit',
}

export enum GuaranteedRateOfferType {
    ApplyForGuaranteedLimit = 'ApplyForGuaranteedLimit',
    HasGuaranteedLimit = 'HasGuaranteedLimit',
    NoGuaranteedLimit = 'NoGuaranteedLimit',
}

export enum CreditCardProductCategory {
    BalanceTransfer = 'BalanceTransfer',
    BestCards = 'BestCards',
    Cashback = 'Cashback',
    Combined = 'Combined',
    PoorCredit = 'PoorCredit',
    Purchase = 'Purchase',
    Rewards = 'Rewards',
    Travel = 'Travel',
}

export interface ScoredCreditCardProductOfferDetail {
    annualFee: number
    balanceTransferAER?: number
    balanceTransferLimit?: number
    cashAdvanceAER?: number
    cashbackValue?: number
    durationOfBalanceRate?: string
    durationOfBalanceRateMonths?: number
    durationOfPurchaseRate?: string
    durationOfPurchaseRateMonths?: number
    higherMinPayment?: number
    internationalCharges?: number
    introBalanceTransferFee: number
    introBalanceTransferRateAER?: number
    introPurchaseRateAER?: number
    introPurchaseRatePerMonth?: number
    minAge?: number
    minIncome?: number
    minPayment?: number
    minPayment1?: number
    purchaseAER: number
    purchaseRatePerMonth?: number
    representativeAPR: number
    representativeExampleCreditLimit?: number
    rewardsValue?: number
    useGuaranteedAPR?: boolean
}

export interface ScoredCreditCardProductCategory {
    displayOrder: number
    featured: boolean
    name: string
}

export enum ScoredCreditCardProductScoreStatus {
    NotRated = 70,
    Scored = 80,
    PreApprovedProduct = 90,
    PreApprovedRate = 100,
}

export interface ScoredCreditCardProductGuaranteedRate {
    guaranteedAPR?: number
    guaranteedCreditLimit?: number
    guaranteedOfferType?: GuaranteedRateOfferType
    isCounterOffer?: boolean
}

export interface ScoredCreditCardProduct {
    balanceTransferComboLimit?: number
    balanceTransferOfferDurationMonths?: number
    balanceTransferText: string
    bulletsPositive: string[]
    cardDetailsUrl: string
    cardFamily?: string
    categoryRank: number
    categoryScore: number
    cons: string[]
    displayOrder: number
    eligibility: string[]
    endOfBalanceTransferPeriod: number
    endOfPurchasePeriod: number
    features: string[]
    guaranteedRate: ScoredCreditCardProductGuaranteedRate
    hasOffer: boolean
    imageUrlLarge: string
    imageUrlMedium: string
    imageUrlSmall: string
    isExclusive: boolean
    isFixedRate: boolean
    isPreApproved: boolean
    likelihood?: number
    maxCreditLimit?: number
    minLikelihood?: number
    name: string
    offerCopy: string
    offerDetail: ScoredCreditCardProductOfferDetail
    offerHeading: string
    offerType: string
    prepopulationToken?: string
    productCategories: ScoredCreditCardProductCategory[]
    productCode: string
    pros: string[]
    provider: string
    purchasesOfferDurationMonths?: number
    purchasesText: string
    rateType: RateType
    score: number
    scoreResult: string
    scoreStatus: ScoredCreditCardProductScoreStatus
    scoringProvider: string
    showGuaranteedAPR: boolean
    showGuaranteedCreditLimit: boolean
    specificRepExampleText?: string
    sponsored: boolean
    status: string
    tmScoreCombinedIntroductoryOffers?: number
}

export interface ScoredCreditCardProductsCategory {
    creditCards: ScoredCreditCardProduct[]
    displayName: string
    id: CreditCardProductCategory
    templateId: CreditCardProductCategory
    totalCreditCards: number
}

export interface CreditCardAlternativeProduct {
    apr: number
    cons: string[]
    description: string
    displayOrder: number
    header: string
    imageUrl: string
    imageUrlLarge: string
    imageUrlMedium: string
    imageUrlSmall: string
    name: string
    productCode: string
    pros: string[]
    representativeExample: string
}

export interface CreditCardAlternativeProductsCategory {
    alternativeProductCategoryId: string
    alternativeProducts: CreditCardAlternativeProduct[]
    alternativeProductsCount: number
    description: string
    displayName: string
    id: string
}

export interface FetchScoredCreditCardProductsResponse {
    scoredCategories: ScoredCreditCardProductsCategory[]
    thirdPartyReference: string
    allActiveCards: ActiveCreditCard[]
    experiments?: ScoredExperiment[]
}

export interface FetchCreditCardAlternativeProductsResponse {
    categories: CreditCardAlternativeProductsCategory[]
}

export enum ModalType {
    AnnualInterestRatesModal = 'AnnualInterestRatesModal',
    BalanceTransferOfferModal = 'BalanceTransferOfferModal',
    BestMatchModal = 'BestMatchModal',
    DevToolClickoutModal = 'DevToolClickoutModal',
    EligibilityIndicatorModal = 'EligibilityIndicatorModal',
    GuaranteedAprModal = 'GuaranteedAprModal',
    GuaranteedCreditLimitModal = 'GuaranteedCreditLimitModal',
    OtherKeyFactsModal = 'OtherKeyFactsModal',
    PreApprovedModal = 'PreApprovedModal',
    PurchaseOfferModal = 'PurchaseOfferModal',
    RepresentativeExampleModal = 'RepresentativeExampleModal',
}

export interface ModalState {
    activeCard: ScoredCreditCardProduct | null
    activeModal: ModalType | null
    creditCardApplicationUrl: string | null
}

export const defaultModalState: ModalState = {
    activeCard: null,
    activeModal: null,
    creditCardApplicationUrl: null,
}

export interface CreditCardsTableTestCase {
    data: FetchScoredCreditCardProductsResponse | { scoredCategories: null }
    highlightCardPath?: string
    showLoading?: boolean
    showUninitialized?: boolean
}

export interface AlternativeProductsTestCase {
    data: FetchCreditCardAlternativeProductsResponse | null
    showError?: boolean
    showLoading?: boolean
    showUninitialized?: boolean
}

export interface CreditCardDetailsTestCase {
    cardId: string
    category: CreditCardProductCategory
    data: FetchScoredCreditCardProductsResponse | { scoredCategories: null }
    showLoading?: boolean
}

export type CreditCardsTestCase = {
    alternativeProducts?: AlternativeProductsTestCase
    creditCard?: CreditCardDetailsTestCase
    creditCards?: CreditCardsTableTestCase
}

export interface ActiveCreditCard {
    name: string
    productCode: string
    imageUrlLarge: string
}

export interface SetMinimumGuaranteedCreditLimitRequest {
    customerId: string
    minimumGuaranteedCreditLimit: number
}

export interface SetMinimumGuaranteedCreditLimitResponse {
    isExpired: boolean
    minimumGuaranteedCreditLimit: number
    updatedAt: string
}

export interface GetMinimumGuaranteedCreditLimitResponse {
    isExpired?: boolean
    minimumGuaranteedCreditLimit?: number
    updatedAt?: string
}

export interface ScoredExperimentMatchFactorRanking {
    name: 'experiment_useMatchFactorRanking'
    variant:
        | 'card_ranking_productrecommender'
        | 'card_ranking_hierarchical'
        | 'control'
}

export type ScoredExperiment = ScoredExperimentMatchFactorRanking
