import { FeatureFlag } from '@appTypes/PagesApi.interface'

export const FEATURE_FLAGS: FeatureFlag = {
    experiment_testthis: 'v1',
    testthis: false,
    experiment_useMatchFactorRanking: 'control',
    experiment_useTokens: 'control',
}

export const FEATURE_FLAG_OPTIONS: Record<keyof FeatureFlag, string[]> = {
    experiment_testthis: ['v1'],
    testthis: ['true', 'false'],
    experiment_useTokens: ['v1', 'control'],
    experiment_useMatchFactorRanking: [
        'card_ranking_productrecommender',
        'card_ranking_hierarchical',
        'control',
    ],
}
