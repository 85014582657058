import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
  }
  
  body {
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
  }

  a,
  article,
  aside,
  audio,
  caption,
  dd,
  div,
  dl,
  dt,
  em,
  fieldset,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  i,
  iframe,
  img,
  ins,
  label,
  li,
  mark,
  nav,
  ol,
  p,
  section,
  small,
  span,
  strong,
  sub,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  ul,
  video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
  }

  html {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      height: 100%;
      font-size: 62.5%;
      -webkit-text-size-adjust: 100%;
  }

  body {
      margin: 0;
      padding: 0;
      height: 100%;
      font-size: 1.6rem;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
  }

  @media only screen and (min-width: 1921px) {
      body {
          max-width: 1920px;
          margin: auto;
      }
  }

  *,
  :after,
  :before {
      box-sizing: inherit;
  }

  ol,
  ul {
      list-style: none;
  }

  table {
      border-collapse: collapse;
      border-spacing: 0;
  }

  main {
      display: block;
  }
`

export default GlobalStyle
