import { PageApiError } from '@api/utils/PageApiError'

export enum AuthErrorType {
    SessionToken = 'SessionToken',
    MagicLinkToken = 'MagicLinkToken',
    Unknown = 'Unknown',
}

function getAuthErrorType(message: string) {
    switch (message) {
        case 'Auth issue with session token':
            return AuthErrorType.SessionToken
        case 'Auth issue with magic link token':
            return AuthErrorType.MagicLinkToken
        default:
            return AuthErrorType.Unknown
    }
}

export function getAuthErrorInfo(error: unknown) {
    const authError =
        error instanceof PageApiError &&
        error.result?.find(a => a.id === 'authError')

    if (authError == null || authError === false) {
        return null
    }

    return {
        message: authError.message,
        errorType: getAuthErrorType(authError.message),
    }
}
