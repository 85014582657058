export function fetchWithTimeout(
    input: RequestInfo | URL,
    init?: (RequestInit & { timeoutMs: number }) | undefined
): Promise<Response> {
    const controller = new AbortController()

    const fetchPromise = fetch(input, { signal: controller.signal, ...init })

    if (init?.signal) {
        init?.signal.addEventListener('abort', () => {
            controller.abort()
        })
    }

    const timeout = setTimeout(() => {
        controller.abort()
    }, init?.timeoutMs)

    return fetchPromise.finally(() => {
        clearTimeout(timeout)
    })
}
