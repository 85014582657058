import { ApiError } from '@appTypes/PagesApi.interface'

interface PageApiErrorOptions {
    message: string
    result: ApiError[]
}

function mapResponseToErrorMessage(response: Response) {
    const statusMessage = (() => {
        switch (response.status) {
            case 400:
                return 'Bad Request'
            case 401:
                return 'Unauthorised'
            case 500:
                return 'Server Error'
            default:
                return response.status
        }
    })()

    const url = new URL(response.url)

    return `fetch request: ${url.pathname} (${statusMessage})`
}

export class PageApiError extends Error {
    protected isPageApiError = true

    result: ApiError[]

    constructor({ message, result }: PageApiErrorOptions) {
        super(message)
        this.message = message
        this.result = result
    }

    static isPageApiError(obj: any): obj is PageApiError {
        return obj.isPageApiError === true
    }

    static getError(payload: { errors: ApiError[] } | ApiError) {
        if ('errors' in payload) {
            return {
                errors: payload.errors,
                __page_api_error__: true,
            }
        }

        return {
            errors: [payload],
            __page_api_error__: true,
        }
    }

    static fromResponse(response: Response, data: any): PageApiError {
        try {
            const result = JSON.parse(data)

            if ('__page_api_error__' in result) {
                return new PageApiError({
                    message: mapResponseToErrorMessage(response),
                    result: result.errors,
                })
            }

            return data
        } catch (err) {
            return data
        }
    }
}
