import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { debounce } from '@utils/debounce'

import useAppQueryState from '@hooks/useAppQueryState'
import useFeatureFlag from '@hooks/useFeatureFlag'
import useLogger from '@hooks/useLogger'

import { LoggerKey } from '@config/logger'

import resetLogoutTimeout from './utils/resetLogoutTimout'

const debouncedResetLogoutTimeout = debounce(resetLogoutTimeout, 2000)

const AuthController = () => {
    const { appQueryState } = useAppQueryState()
    const router = useRouter()
    const { logInfo } = useLogger()
    const isTokensFeatureEnabled =
        useFeatureFlag('experiment_useTokens') === 'v1'

    useEffect(() => {
        if (!isTokensFeatureEnabled) {
            return
        }

        if (typeof document !== 'undefined') {
            document.addEventListener('click', debouncedResetLogoutTimeout)
        }

        return () => {
            if (typeof document !== 'undefined') {
                document.removeEventListener(
                    'click',
                    debouncedResetLogoutTimeout
                )
            }
        }
    }, [])

    useEffect(() => {
        if ('reauth' in router.query) {
            logInfo(LoggerKey.AuthControllerReauth, {
                customerId: appQueryState.customerId,
            })
        }
    }, [])

    return null
}

export default AuthController
