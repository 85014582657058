import { getAppConfig } from '@utils/getAppConfig'

/**
 * Redirect the customer to the account login so
 * they can get a new session token. When successfully
 * logged in, the customer should be taken back to the
 * cards table.
 *
 * @returns The redirect url
 */
export function getAuthRedirectUrl() {
    const accountUrl = getAppConfig().ACCOUNT_URL

    if (typeof window !== 'undefined') {
        const currentUrl = new URL(window.location.href)

        /**
         * We don't want to persist the magic link token
         * as this is a one-token token that won't be needed
         * when the customer logs in and has a session token.
         */
        currentUrl.searchParams.delete('mlt')

        /**
         * We want to ensure the customer remains on the use tokens
         * experiment journey so we validate the scored endpoint.
         */
        currentUrl.searchParams.set('experiment_useTokens', 'v1')

        /**
         * We use this for tracking to see how many times customers
         * reauth from the login page.
         */
        currentUrl.searchParams.set('reauth', 'true')

        return `${accountUrl}login?flash=unauthorised&returnUrl=${encodeURIComponent(
            currentUrl.toString()
        )}`
    }

    return `${accountUrl}login`
}
