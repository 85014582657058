import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'

import { FlashMessageVariants } from '@appTypes/flashMessage'

export const flashMessageCopy = {
    addressFailure: {
        title: 'We couldn’t find your address',
        text: 'Results from your search may be based on existing information.',
    },

    addressSkipped: {
        title: 'Your details were not updated',
        text: 'Results from your search may be based on existing information.',
    },

    success: {
        title: 'Address successfully updated',
        text: 'Updating your details means you may see even better offers!',
    },
}

export const flashMessages = {
    addressFailure: {
        title: (
            <Box>
                <Text weight="bold">
                    {flashMessageCopy.addressFailure.title}
                </Text>
                <Text>{flashMessageCopy.addressFailure.text}</Text>
            </Box>
        ),
        variation: FlashMessageVariants.error,
    },

    addressSkipped: {
        title: (
            <Box>
                <Text weight="bold">
                    {flashMessageCopy.addressSkipped.title}
                </Text>
                <Text>{flashMessageCopy.addressSkipped.text}</Text>
            </Box>
        ),
        variation: FlashMessageVariants.error,
    },

    success: {
        title: (
            <Box>
                <Text weight="bold">{flashMessageCopy.success.title}</Text>
                <Text>{flashMessageCopy.success.text}</Text>
            </Box>
        ),
        variation: FlashMessageVariants.success,
    },
}
